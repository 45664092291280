<template>
  <div>

    <!-- Alert: No item found -->
    <b-alert
      variant="danger"
      :show="elementData === undefined"
    >
      <h4 class="alert-heading">
        {{ $t(RESOURCES_ELEMENT_NAME + '.search.error.fetching_data') }}
      </h4>
    </b-alert>
    <template v-if="elementData">
      <b-card>
        <b-card-header>
          <div class="text-primary h2 col-12">
            <b-row>
              <!-- Right Col: Table -->
              <b-col
                cols="9"
                xl="9"
              >
                <span class="font-weight-bold">
                  {{ $t('fullInvoice.section.edit') }}
                </span>
              </b-col>
            </b-row>
          </div>
        </b-card-header>
        <b-card-body>
          <validation-observer
            ref="elementForm"
            #default="{ invalid }"
          >
            <b-form>
              <b-row>
                <b-col
                  cols="6"
                  lg="4"
                  md="4"
                >
                  <b-form-group
                    label-for="number"
                  >
                    <template v-slot:label>
                      {{ $t('fullInvoice.object.number') }}
                    </template>
                    <b-form-input
                      v-model="elementData.number"
                      :disabled="true"
                    />
                  </b-form-group>
                </b-col>
                <b-col
                  cols="6"
                  lg="4"
                  md="4"
                >
                  <b-form-group
                    label-for="arl"
                  >
                    <template v-slot:label>
                      {{ $t('fullInvoice.object.arl') }}
                    </template>
                    <b-form-input
                      v-model="elementData.arl.name"
                      :disabled="true"
                    />
                  </b-form-group>
                </b-col>
                <b-col
                  cols="6"
                  lg="4"
                  md="4"
                >
                  <b-form-group
                    label-for="nit"
                  >
                    <template v-slot:label>
                      {{ $t('fullInvoice.object.nit') }}
                    </template>
                    <b-form-input
                      v-model="elementData.arl.nit"
                      :disabled="true"
                    />
                  </b-form-group>
                </b-col>
              </b-row>
              <b-row>
                <b-col
                  cols="6"
                  lg="4"
                  md="4"
                >
                  <b-form-group
                    label-for="date"
                  >
                    <template v-slot:label>
                      {{ $t('fullInvoice.object.date') }}
                    </template>
                    <b-form-input
                      v-model="invoiceDate"
                      :disabled="true"
                    />
                  </b-form-group>
                </b-col>
                <b-col
                  cols="6"
                  lg="4"
                  md="4"
                >

                  <b-form-group
                    label-for="arl_filed_date"
                  >
                    <template v-slot:label>
                      {{ $t('fullInvoice.object.arl_filed_date') }}
                    </template>
                    <b-form-input
                      v-model="arl_filed_date"
                      :disabled="true"
                    />
                  </b-form-group>

                </b-col>
                <b-col
                  cols="6"
                  lg="4"
                  md="4"
                >
                  <b-form-group
                    label-for="arl_filed_number"
                  >
                    <template v-slot:label>
                      {{ $t('fullInvoice.object.arl_filed_number') }}
                    </template>
                    <b-form-input
                      v-model="elementData.arl_filed_number"
                      :disabled="true"
                    />
                  </b-form-group>
                </b-col>
              </b-row>
              <b-row>
                <b-col
                  cols="6"
                  lg="4"
                  md="4"
                >
                  <b-form-group
                    label-for="value"
                  >
                    <template v-slot:label>
                      {{ $t('fullInvoice.object.value') }}
                    </template>
                    <b-form-input
                      v-model="elementData.value"
                      :disabled="true"
                    />
                  </b-form-group>
                </b-col>
                <b-col
                  cols="6"
                  lg="2"
                  md="2"
                >
                  <b-form-group
                    label-for="fuente"
                  >
                    <template v-slot:label>
                      {{ $t('fullInvoice.object.fuente') }}
                    </template>
                    <b-form-input
                      v-model="elementData.fuente"
                      :disabled="true"
                      type="number"
                    />
                  </b-form-group>
                </b-col>
                <b-col
                  cols="6"
                  lg="2"
                  md="2"
                >
                  <b-form-group
                    label-for="ica"
                  >
                    <template v-slot:label>
                      {{ $t('fullInvoice.object.ica') }}
                    </template>
                    <b-form-input
                      v-model="elementData.ica"
                      :disabled="true"
                      type="number"
                    />
                  </b-form-group>
                </b-col>
                <b-col
                  cols="6"
                  lg="2"
                  md="2"
                >
                  <b-form-group
                    label-for="other_taxes"
                  >
                    <template v-slot:label>
                      {{ $t('fullInvoice.object.other_taxes') }}
                    </template>
                    <b-form-input
                      v-model="elementData.other_taxes"
                      :disabled="true"
                      type="number"
                    />
                  </b-form-group>
                </b-col>
                <b-col
                  cols="6"
                  lg="2"
                  md="2"
                >
                  <b-form-group
                    label-for="iva"
                  >
                    <template v-slot:label>
                      {{ $t('fullInvoice.object.iva') }}
                    </template>
                    <b-form-input
                      v-model="elementData.iva"
                      :disabled="true"
                      type="number"
                    />
                  </b-form-group>
                </b-col>
              </b-row>
              <b-row>
                <b-col
                  cols="6"
                  lg="4"
                  md="4"
                >
                  <b-form-group
                    label-for="charged_value"
                  >
                    <template v-slot:label>
                      {{ $t('fullInvoice.object.charged_value') }}
                    </template>
                    <b-form-input
                      v-model="elementData.charged_value"
                      :disabled="true"
                      type="number"
                    />
                  </b-form-group>
                </b-col>
                <b-col
                  cols="6"
                  lg="4"
                  md="4"
                >
                  <b-form-group
                    label-for="received_value"
                  >
                    <template v-slot:label>
                      {{ $t('fullInvoice.object.received_value') }}
                    </template>
                    <b-form-input
                      v-model="elementData.received_value"
                      :disabled="true"
                      type="number"
                    />
                  </b-form-group>
                </b-col>
                <b-col
                  cols="6"
                  lg="4"
                  md="4"
                >

                  <b-form-group
                    label-for="pay_date"
                  >
                    <template v-slot:label>
                      {{ $t('fullInvoice.object.pay_date') }}
                    </template>
                    <b-form-input
                      v-model="pay_date"
                      :disabled="true"
                    />
                  </b-form-group>
                </b-col>
              </b-row>
              <b-row>
                <b-col
                  cols="6"
                  lg="4"
                  md="4"
                >
                  <b-form-group
                    label-for="credit"
                  >
                    <template v-slot:label>
                      {{ $t('fullInvoice.object.credit') }}
                    </template>
                    <b-form-input
                      v-model="elementData.credit"
                      :disabled="true"
                      type="number"
                    />
                  </b-form-group>
                </b-col>
                <b-col
                  cols="6"
                  lg="4"
                  md="4"
                >
                  <b-form-group
                    label-for="balance"
                  >
                    <template v-slot:label>
                      {{ $t('fullInvoice.object.balance') }}
                    </template>
                    <b-form-input
                      v-model="elementData.balance"
                      :disabled="true"
                      type="number"
                    />
                  </b-form-group>
                </b-col>
                <b-col
                  cols="6"
                  lg="4"
                  md="4"
                >
                  <b-form-group
                    label-for="arl"
                  >
                    <template v-slot:label>
                      {{ $t('fullInvoice.object.status') }}
                    </template>

                    <v-select
                      v-model="elementData.status_id"
                      :options="invoiceStatusList"
                      label="value"
                      value="id"
                      :reduce="option => option.id"
                      :disabled="true"
                    />
                  </b-form-group>
                </b-col>
              </b-row>
              <b-row>
                <b-col cols="12">
                  <b-form-group
                    label-for="observations"
                  >
                    <template v-slot:label>
                      {{ $t('fullInvoice.object.observations') }}
                    </template>
                    <b-form-textarea
                      id="observations"
                      v-model="elementData.observations"
                      :disabled="true"
                      maxlength="250"
                      max="250"
                    />
                  </b-form-group>
                </b-col>
              </b-row>
              <br>
              <div class="text-primary h2 col-12">
                <b-row>
                  <!-- Right Col: Table -->
                  <b-col
                    cols="9"
                    xl="9"
                  >
                    <span class="font-weight-bold">
                      {{ $t('fullInvoice.section.patient_info') }}
                    </span>
                  </b-col>
                </b-row>
              </div>

              <b-row>
                <b-col
                  cols="6"
                  lg="4"
                  md="4"
                >
                  <b-form-group
                    label-for="document_type"
                  >
                    <template v-slot:label>
                      {{ $t('fullInvoice.object.patinet.document_type') }}
                    </template>
                    <b-form-input
                      v-model="patient.document_type.value"
                      :disabled="true"
                    />
                  </b-form-group>
                </b-col>
                <b-col
                  cols="6"
                  lg="4"
                  md="4"
                >
                  <b-form-group
                    label-for="identity"
                  >
                    <template v-slot:label>
                      {{ $t('fullInvoice.object.patinet.identity') }}
                    </template>
                    <b-form-input
                      v-model="patient.identity"
                      :disabled="true"
                    />
                  </b-form-group>
                </b-col>
                <b-col
                  cols="6"
                  lg="4"
                  md="4"
                >
                  <b-form-group
                    label-for="file_number"
                  >
                    <template v-slot:label>
                      {{ $t('fullInvoice.object.patinet.file_number') }}
                    </template>
                    <b-form-input
                      v-model="patient.identity"
                      :disabled="true"
                    />
                  </b-form-group>
                </b-col>
              </b-row>
              <b-row>
                <b-col
                  cols="6"
                  lg="3"
                  md="3"
                >
                  <b-form-group
                    label-for="first_name"
                  >
                    <template v-slot:label>
                      {{ $t('fullInvoice.object.patinet.first_name') }}
                    </template>
                    <b-form-input
                      v-model="patient.first_name"
                      :disabled="true"
                    />
                  </b-form-group>
                </b-col>
                <b-col
                  cols="6"
                  lg="3"
                  md="3"
                >
                  <b-form-group
                    label-for="second_name"
                  >
                    <template v-slot:label>
                      {{ $t('fullInvoice.object.patinet.second_name') }}
                    </template>
                    <b-form-input
                      v-model="patient.second_name"
                      :disabled="true"
                    />
                  </b-form-group>
                </b-col>
                <b-col
                  cols="6"
                  lg="3"
                  md="3"
                >
                  <b-form-group
                    label-for="first_surname"
                  >
                    <template v-slot:label>
                      {{ $t('fullInvoice.object.patinet.first_surname') }}
                    </template>
                    <b-form-input
                      v-model="patient.first_surname"
                      :disabled="true"
                    />
                  </b-form-group>
                </b-col>
                <b-col
                  cols="6"
                  lg="3"
                  md="3"
                >
                  <b-form-group
                    label-for="second_surname"
                  >
                    <template v-slot:label>
                      {{ $t('fullInvoice.object.patinet.second_surname') }}
                    </template>
                    <b-form-input
                      v-model="patient.second_surname"
                      :disabled="true"
                    />
                  </b-form-group>
                </b-col>
              </b-row>

              <b-row>
                <b-col cols="12">
                  <b-form-group
                    label-for="services"
                  >
                    <template v-slot:label>
                      {{ $t('fullInvoice.object.services') }}
                    </template>
                    <div
                      v-for="invoice in invoiceItems"
                      :key="invoice.id"
                      class="btn btn-outline-dark disabled"
                    >
                      {{ getFormattedData(invoice) }}
                    </div>
                  </b-form-group>
                </b-col>
              </b-row>
              <b-row class="d-flex justify-content-end">
                <b-button
                  class="mb-1 mb-sm-0 mr-0 mr-sm-1"
                  @click="router().go(-1)"
                >
                  {{ $t('actions.back') }}
                </b-button>
              </b-row>
            </b-form>
          </validation-observer>
        </b-card-body>
      </b-card>

    </template>

  </div>
</template>

<script>
import {
  BRow, BCol, BButton, BAlert, BCard, BCardHeader, BCardBody, BFormInput, BFormGroup, BForm, BFormTextarea,
} from 'bootstrap-vue'

import { ValidationObserver } from 'vee-validate'

import store from '@/store'
import router from '@/router'
import moment from 'moment'
import vSelect from 'vue-select'
import ElementStoreModule from '../storeModule'
import '@core/scss/vue/libs/vue-select.scss'

export default {

  components: {
    BRow,
    BCol,
    BButton,
    BAlert,
    BCard,
    BCardHeader,
    BCardBody,
    BFormInput,
    BFormGroup,
    BForm,
    ValidationObserver,
    BFormTextarea,
    vSelect,
  },
  data() {
    return {
      patient: {
        document_type: {
          value: undefined,
        },
      },
      elementData: {
        arl: {
          value: undefined,
          nit: undefined,
        },
      },
      ELEMENT_APP_STORE_MODULE_NAME: '',
      RESOURCES_ELEMENT_NAME: '',
      type: undefined,
      id: undefined,
      invoiceStatusList: [],
      invoiceItems: [],
      invoiceDate: undefined,
      arl_filed_date: undefined,
      pay_date: undefined,
    }
  },
  async mounted() {
    this.RESOURCES_ELEMENT_NAME = 'fullInvoice'
    this.ELEMENT_APP_STORE_MODULE_NAME = `app-${this.RESOURCES_ELEMENT_NAME}`

    this.id = router.currentRoute.params.id
    // Register module
    if (!store.hasModule(this.ELEMENT_APP_STORE_MODULE_NAME)) store.registerModule(this.ELEMENT_APP_STORE_MODULE_NAME, ElementStoreModule)

    if (this.id) {
      await this.fetchElement()
    }
    this.invoiceStatusList = await this.fetchInvoiceStatus()
  },
  unmounted() {
    // UnRegister on leave
    if (store.hasModule(this.ELEMENT_APP_STORE_MODULE_NAME)) store.unregisterModule(this.ELEMENT_APP_STORE_MODULE_NAME)
  },
  methods: {
    getFormattedDate(date) {
      if (date) {
        return moment(date).format('DD/MM/YYYY')
      }
      return '--'
    },
    getFormattedData(invoice) {
      return `${invoice.cups} - ${invoice.service_data.description} - Fecha: ${
        moment(invoice.service_date).format('DD/MM/YYYY')} - Cod. Servicio ${invoice.service_data.intern_code}`
    },
    router() {
      return router
    },
    async fetchInvoiceStatus() {
      return store.dispatch(`${this.ELEMENT_APP_STORE_MODULE_NAME}/fetchInvoiceStatus`, { }).then(response => {
        const list = response.data.items
        return list
      }).catch(error => {
        if (error.response.status === 404) {
          return undefined
        }
      })
    },
    fetchInvoiceItems(invoiceId) {
      store.dispatch(`${this.ELEMENT_APP_STORE_MODULE_NAME}/fetchInvoiceItems`, { id: invoiceId })
        .then(response => {
          this.invoiceItems = response.data.items
          console.info('invoiceItems:', this.invoiceItems)
        })
        .catch(error => {
          if (error.response.status === 404) {
            console.info('404', this.elementData)
            this.elementData = undefined
          }
        })
    },
    fetchElement() {
      store.dispatch(`${this.ELEMENT_APP_STORE_MODULE_NAME}/fetchInvoice`, { id: this.id })
        .then(response => {
          this.elementData = response.data
          console.info('this.elementData:', this.elementData)

          this.invoiceDate = this.getFormattedDate(this.elementData.date)
          this.arl_filed_date = this.getFormattedDate(this.elementData.arl_filed_date)
          this.pay_date = this.getFormattedDate(this.elementData.pay_date)

          this.fetchPatient(this.elementData.patient_id)
          this.fetchInvoiceItems(this.elementData.id)
        })
        .catch(error => {
          if (error.response.status === 404) {
            console.info('404', this.elementData)
            this.elementData = undefined
          }
        })
    },
    fetchPatient(patient_id) {
      store.dispatch(`${this.ELEMENT_APP_STORE_MODULE_NAME}/fetch_patient`, { id: patient_id })
        .then(response => {
          this.patient = response.data
        })
        .catch(error => {
          if (error.response.status === 404) {
            console.info('404', this.elementData)
            this.patient = undefined
          }
        })
    },
  },
}
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
